import * as Dialog from "@radix-ui/react-dialog";
import React, { useState } from "react";
import { db } from "../firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
function Modal({ onDownload }) {
  async function addDataToFirestore(name, email, mobile, location) {
    try {
      const docRef = await addDoc(collection(db, "brochure"), {
        name: name,
        email: email,
        mobile: mobile,
        location: location,
        timestamp: serverTimestamp(),
      });

      return true;
    } catch (e) {
      console.error("Error adding document: ", e);
      return false;
    }
  }

  const [fullnames, setFullnames] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [location, setLocation] = useState("");

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    // e.preventDefault();
    const added = await addDataToFirestore(
      fullnames,
      emailId,
      mobileNum,
      location
    );
    if (added) {
      setFullnames("");
      setEmailId("");
      setMobileNum("");
      setLocation("");

      alert("Your message has been sent successfully!");
    } else {
      alert("Something went wrong. Please try again later.");
    }
  };

  // validation
  const validate = (e) => {
    let names = document.getElementById("fullnames").value;
    let emailId = document.getElementById("emailId").value;
    let mobileNum = document.getElementById("mobileNum").value;
    let location = document.getElementById("location").value;

    let error = false;

    if (names.length >= 2) {
      document.getElementById("valid-names").style.display = "block";
      document.getElementById("invalid-names").style.display = "none";
    } else {
      error = true;
      document.getElementById("invalid-names").style.display = "block";
      document.getElementById("valid-names").style.display = "none";
    }

    if (
      emailId.includes("@") &&
      emailId.includes(".") &&
      emailId.indexOf("@") > 0 &&
      // email.substr(email.lastIndexOf('.')+1).length>=2)
      emailId.substr(emailId.lastIndexOf(".") + 1).length >= 2
    ) {
      document.getElementById("valid-emailId").style.display = "block";
      document.getElementById("invalid-emailId").style.display = "none";
    } else {
      error = true;
      document.getElementById("invalid-emailId").style.display = "block";
      document.getElementById("valid-emailId").style.display = "none";
    }

    let mobile = parseInt(mobileNum);
    //using input = number in html
    if (!isNaN(mobile) && mobile > 6000000000 && mobile < 9999999999) {
      document.getElementById("valid-mobileNum").style.display = "block";
      document.getElementById("invalid-mobileNum").style.display = "none";
    } else {
      error = true;
      document.getElementById("valid-mobileNum").style.display = "none";
      document.getElementById("invalid-mobileNum").style.display = "block";
    }

    if (location.length > 0) {
      document.getElementById("valid-location").style.display = "block";
      document.getElementById("invalid-location").style.display = "none";
    } else {
      error = true;
      document.getElementById("valid-location").style.display = "none";
      document.getElementById("invalid-location").style.display = "block";
    }

    if (!error) {
      resetFields();
    } else {
      alert("Please enter valid details");
    }

    function resetFields() {
      //reset only resets field of HTML forms
      document.getElementById("registration-form").reset();

      document.getElementById("valid-names").style.display = "none";
      document.getElementById("valid-emailId").style.display = "none";
      document.getElementById("valid-mobileNum").style.display = "none";
      document.getElementById("valid-location").style.display = "none";
    }
    return !error;
  };

  return (
    <Dialog.Root className="inset-0 z-10 overflow-y-auto">
      <Dialog.Trigger className="w-1/2 py-2 my-2 mx-auto  shadow-sm rounded-md bg-indigo-600 text-white mt-4 flex items-center justify-center">
        Brochure
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
        <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4">
          <div className="bg-white rounded-md shadow-lg px-4 py-6">
            <div className="flex items-center justify-end"></div>
            <div className="max-w-sm mx-auto space-y-3 text-center ">
              <Dialog.Title className="text-lg font-medium text-gray-800 ">
                Enter the details
              </Dialog.Title>

              <Dialog.Description className=" text-sm text-gray-600">
                <p>Fill the below form and download the brochure for free</p>
              </Dialog.Description>

              {/* Contact form */}

              <div className="bg-white p-6 rounded-lg shadow-lg shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] w-full max-w-md mx-auto">
                <h2 className="text-2xl font-bold mb-10 text-purple-800 text-center">
                  Register for free
                </h2>
                <form>
                  <label htmlFor="fullnames"></label>
                  <input
                    className="border border-purple-400 mb-4 w-full p-2 rounded-md outline-purple-400"
                    type="text"
                    placeholder="Enter your Name"
                    id="fullnames"
                    onChange={(e) => setFullnames(e.target.value)}
                    value={fullnames}
                  />
                  <div
                    id="valid-names"
                    className="text-green-500 hidden relative bottom-2"
                  >
                    Looks good!
                  </div>
                  <div
                    id="invalid-names"
                    className="text-red-500 hidden relative bottom-2"
                  >
                    Please enter a valid name.
                  </div>
                  <label htmlFor="emailId"></label>
                  <input
                    className="border border-purple-400 mb-4 w-full p-2 rounded-md outline-purple-400"
                    type="emailId"
                    placeholder="Enter your Email Id"
                    id="emailId"
                    onChange={(e) => setEmailId(e.target.value)}
                    value={emailId}
                  />

                  <div
                    id="valid-emailId"
                    className="text-green-500 hidden relative bottom-2"
                  >
                    Looks good!
                  </div>
                  <div
                    id="invalid-emailId"
                    className="text-red-500 hidden relative bottom-2"
                  >
                    Please enter a valid email.
                  </div>

                  <label htmlFor="mobileNum"></label>
                  <input
                    className="border border-purple-400 mb-4 w-full p-2 rounded-md outline-purple-400"
                    type="text"
                    placeholder="Enter your Mobile Number"
                    id="mobileNum"
                    onChange={(e) => setMobileNum(e.target.value)}
                    value={mobileNum}
                  />

                  <div
                    id="valid-mobileNum"
                    className="text-green-500 hidden relative bottom-2"
                  >
                    Looks good!
                  </div>
                  <div
                    id="invalid-mobileNum"
                    className="text-red-500 hidden relative bottom-2"
                  >
                    Please enter a valid mobile number.
                  </div>

                  <label htmlFor="location"></label>
                  <input
                    className="border border-purple-400 mb-4 w-full p-2 rounded-md outline-purple-400"
                    type="text"
                    placeholder="Location"
                    id="location"
                    onChange={(e) => setLocation(e.target.value)}
                    value={location}
                  />

                  <div
                    id="valid-location"
                    className="text-green-500 hidden relative bottom-2"
                  >
                    Looks good!
                  </div>
                  <div
                    id="invalid-location"
                    className="text-red-500 hidden relative bottom-2"
                  >
                    Please enter a valid location.
                  </div>
                  <Dialog.Close asChild>
                    <button
                      className="w-full px-3 py-2 rounded-md bg-indigo-600 text-white shadow-sm"
                      onClick={async (e) => {
                        // Add your download logic here
                        const isValid = validate();
                        if (!isValid) {
                          e.preventDefault(); // Prevent the default behavior to keep the dialog open
                          return;
                        }

                        await onDownload();
                        e.preventDefault();
                        await handleSubmit(e);
                      }}
                    >
                      Download
                    </button>
                  </Dialog.Close>
                </form>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default Modal;
