const stats = [
  {
    id: 1,
    name: "100 %",
    stat: "Placement Guarantee*",
    change: "122",
    changeType: "increase",
  },
  {
    id: 2,
    name: "₹ 18 LPA",
    stat: "Highest salary offered",
    change: "5.4%",
    changeType: "increase",
  },
  {
    id: 3,
    name: "₹ 6 LPA",
    stat: "Average Salary",
    change: "3.2%",
    changeType: "decrease",
  },
];

export default function Example() {
  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 p-8">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-[conic-gradient(at_top_left,_var(--tw-gradient-stops))]  px-4 pt-5  shadow sm:px-6 sm:pt-6 "
          >
            <dt>
              <p className="text-center truncate text-2xl font-medium text-blue-900 ">
                {item.name}
              </p>
            </dt>
            <dd className="text-center items-baseline pb-6 sm:pb-7">
              <p className="text-center text-2xl font-semibold text-blue-900 ">
                {item.stat}
              </p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
