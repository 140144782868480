import React from "react";
import Landing from "../../components/Landing";
import Hero from "../../components/Hero";
import Courses from "../../components/Courses";
import Features from "../../components/Features";
import Testimonials from "../../components/Testimonials";
import Footer from "../../components/Footer";
import Trainers from "../../components/Trainers";
import Testimonial from "../..//components/Testimonial";
import fb from "../../assets/fb.webp";
import linkedin from "../../assets/linkedin_logo.webp";
import insta from "../../assets/ig.webp";
import youtube from "../../assets/youtube_logo.webp";

const HomeScreen = () => {
  return (
    <>
      <div className="hidden md:flex flex-col gap-4 fixed left-4 top-48 z-10">
        <a
          href="https://www.facebook.com/iqschoolupskill/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={fb}
            alt="facebook"
            style={{ width: "40px", height: "40px" }}
          />
        </a>

        <a
          href="https://www.instagram.com/iqschool_upskill/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={insta}
            alt="instagram"
            style={{ width: "40px", height: "40px" }}
          />
        </a>

        <a
          href="https://www.linkedin.com/company/iqschool/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={linkedin}
            alt="linkedin"
            style={{ width: "40px", height: "40px" }}
          />
        </a>

        <a
          href="https://www.youtube.com/@IQSchoolupskill/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={youtube}
            alt="youtube"
            style={{ width: "40px", height: "40px" }}
          />
        </a>
      </div>

      <Landing />
      <Hero />
      <Courses />
      <Features />
      <Testimonials />
      <Testimonial />
      <Trainers />
      <Footer />
    </>
  );
};

export default HomeScreen;
