import { db } from "../firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import React, { useState } from "react";

const ContactForm = () => {
  async function addDataToFirestore(
    name,
    email,
    mobile,
    qualification,
    year,
    course
  ) {
    try {
      const docRef = await addDoc(collection(db, "messages"), {
        name: name,
        email: email,
        mobile: mobile,
        qualification: qualification,
        year: year,
        course: course,
        timestamp: serverTimestamp(),
      });

      return true;
    } catch (e) {
      console.error("Error adding document: ", e);
      return false;
    }
  }
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [qualification, setQualification] = useState("");
  const [year, setYear] = useState("");
  const [course, setCourse] = useState("");

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const added = await addDataToFirestore(
      fullname,
      email,
      mobile,
      qualification,
      year,
      course
    );
    if (added) {
      setFullname("");
      setEmail("");
      setMobile("");
      setQualification("");
      setYear("");
      setCourse("");

      alert("Your message has been sent successfully!");
    } else {
      alert("Something went wrong. Please try again later.");
    }
  };
  // validation
  function validate(e) {
    e.preventDefault();
    let name = document.getElementById("fullname").value;
    let email = document.getElementById("email").value;
    let mobile = document.getElementById("mobile").value; //number from input is converted into string
    let qualification = document.getElementById("qualification").value;
    let year = document.getElementById("year").value;
    let course = document.getElementById("course").value;
    // console.log(name, email, year, mobile, qualification, course);
    // console.log(typeof mobile);
    //To check if error exists to display alert of success message
    let error = false;

    if (name.length >= 2) {
      document.getElementById("valid-name").style.display = "block";
      document.getElementById("invalid-name").style.display = "none";
    } else {
      error = true;
      document.getElementById("invalid-name").style.display = "block";
      document.getElementById("valid-name").style.display = "none";
    }

    /*  Email:
        1.Must include '@'
        2.Must include '.'
        3.At least one character before '@' 
        4.At least two characters after last'.'
    */

    // my code (email.charAt(0)!=='@') && (email.charAt(email.length-1)!=='.' && (email.charAt(email.length-2)!=='.'))
    if (
      email.includes("@") &&
      email.includes(".") &&
      email.indexOf("@") > 0 &&
      // email.substr(email.lastIndexOf('.')+1).length>=2)
      email.substr(email.lastIndexOf(".") + 1).length >= 2
    ) {
      document.getElementById("valid-email").style.display = "block";
      document.getElementById("invalid-email").style.display = "none";
    } else {
      error = true;
      document.getElementById("invalid-email").style.display = "block";
      document.getElementById("valid-email").style.display = "none";
    }

    let mobileNum = parseInt(mobile);
    //using input = number in html
    if (!isNaN(mobileNum) && mobileNum > 6000000000 && mobileNum < 9999999999) {
      document.getElementById("valid-mobile").style.display = "block";
      document.getElementById("invalid-mobile").style.display = "none";
    } else {
      error = true;
      document.getElementById("valid-mobile").style.display = "none";
      document.getElementById("invalid-mobile").style.display = "block";
    }
    //qualification
    if (qualification !== "SELECT QUALIFICATION") {
      document.getElementById("valid-qualification").style.display = "block";
      document.getElementById("invalid-qualification").style.display = "none";
    } else {
      error = true;
      document.getElementById("invalid-qualification").style.display = "block";
      document.getElementById("valid-qualification").style.display = "none";
    }

    if (year !== "SELECT YEAR OF PASSING") {
      document.getElementById("valid-year").style.display = "block";
      document.getElementById("invalid-year").style.display = "none";
    } else {
      error = true;
      document.getElementById("invalid-year").style.display = "block";
      document.getElementById("valid-year").style.display = "none";
    }

    if (course !== "SELECT COURSE") {
      document.getElementById("valid-course").style.display = "block";
      document.getElementById("invalid-course").style.display = "none";
    } else {
      error = true;
      document.getElementById("invalid-course").style.display = "block";
      document.getElementById("valid-course").style.display = "none";
    }

    // If no error
    // 1. Show alert with Success Message
    // 2.Reset all fields

    // if(error === false)
    if (!error) {
      handleSubmit();
      resetFields();
    } else {
      alert("Please enter valid details");
    }
  }

  function resetFields() {
    //reset only resets field of HTML forms
    document.getElementById("registration-form").reset();

    document.getElementById("valid-name").style.display = "none";
    document.getElementById("valid-email").style.display = "none";
    document.getElementById("valid-mobile").style.display = "none";
    document.getElementById("valid-qualification").style.display = "none";
    document.getElementById("valid-year").style.display = "none";
    document.getElementById("valid-course").style.display = "none";
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] w-full max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-10 text-purple-800 text-center">
        Register for free
      </h2>
      <form onSubmit={validate} id="registration-form">
        <label htmlFor="fullname"></label>
        <input
          className="border border-purple-400 mb-4 w-full p-2 rounded-md outline-purple-400"
          type="text"
          placeholder="Enter your Name"
          id="fullname"
          onChange={(e) => setFullname(e.target.value)}
          value={fullname}
        />
        <div
          id="valid-name"
          className="text-green-500 hidden relative bottom-2"
        >
          Looks good!
        </div>
        <div
          id="invalid-name"
          className="text-red-500 hidden relative bottom-2"
        >
          Please enter a valid name.
        </div>
        <label htmlFor="email"></label>
        <input
          className="border border-purple-400 mb-4 w-full p-2 rounded-md outline-purple-400"
          type="email"
          placeholder="Enter your Email Id"
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <div
          id="valid-email"
          className="text-green-500 hidden relative bottom-2"
        >
          Looks good!
        </div>
        <div
          id="invalid-email"
          className="text-red-500 hidden relative bottom-2"
        >
          Please enter a valid email.
        </div>
        <label htmlFor="mobile"></label>
        <input
          className="border border-purple-400 mb-4 w-full p-2 rounded-md outline-purple-400"
          type="text"
          placeholder="Enter your Mobile Number"
          id="mobile"
          onChange={(e) => setMobile(e.target.value)}
          value={mobile}
        />
        <div
          id="valid-mobile"
          className="text-green-500 hidden relative bottom-2"
        >
          Looks good!
        </div>
        <div
          id="invalid-mobile"
          className="text-red-500 hidden relative bottom-2"
        >
          Please enter a valid mobile number.
        </div>

        <label htmlFor="qualification"></label>
        <select
          className="border border-purple-400 mb-4 w-full p-2 rounded-md outline-purple-400"
          id="qualification"
          onChange={(e) => setQualification(e.target.value)}
        >
          <option>SELECT QUALIFICATION</option>

          <option value="BE / B.Tech / MCA">BE / B.Tech / MCA</option>
          <option value="BCA, BSc, B.com, BBA">BCA, BSc, B.com, BBA</option>
          <option value="Others">Others</option>
        </select>

        <div
          id="valid-qualification"
          className="text-green-500 hidden relative bottom-2"
        >
          Looks good!
        </div>
        <div
          id="invalid-qualification"
          className="text-red-500 hidden relative bottom-2"
        >
          Please enter a valid qualification.
        </div>
        <label htmlFor="year"></label>
        <select
          className="border border-purple-400 mb-4 w-full p-2 rounded-md outline-purple-400"
          id="year"
          onChange={(e) => setYear(e.target.value)}
        >
          <option>SELECT YEAR OF PASSING</option>

          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="Others">Others</option>
        </select>

        <div
          id="valid-year"
          className="text-green-500 hidden relative bottom-2"
        >
          Looks good!
        </div>
        <div
          id="invalid-year"
          className="text-red-500 hidden relative bottom-2"
        >
          Please enter a valid year.
        </div>

        <label htmlFor="course"></label>
        <select
          className="border border-purple-400 mb-4 w-full p-2 rounded-md outline-purple-400"
          id="course"
          onChange={(e) => setCourse(e.target.value)}
        >
          <option>SELECT COURSE</option>
          <option value="Java">Java FullStack</option>
          <option value="Python">Python FullStack</option>
          <option value="Data Science">Data Science</option>
          <option value="Web Development">Web Development - MERN</option>
          <option value="App Development">
            Data Structures and Algorithms - DSA
          </option>
          <option value="Devops">Devops</option>
          <option value="Data Analytics">Data Analytics</option>
          <option value="Internship">Internship</option>
          <option value="Others"></option>
        </select>

        <div
          id="valid-course"
          className="text-green-500 hidden relative bottom-2"
        >
          Looks good!
        </div>
        <div
          id="invalid-course"
          className="text-red-500 hidden relative bottom-2"
        >
          Please enter a valid course.
        </div>
        <button
          className="bg-purple-600 text-white w-full py-2 rounded-md"
          type="submit"
        >
          Enroll Now
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
