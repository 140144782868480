import React from "react";
import IqsLogo from "../assets/iqslogo.webp";
import Akhil from "../assets/akhil2.webp";
import Venk from "../assets/venk2.webp";
import Bala from "../assets/bala.webp";
import TechMahindra from "../assets/techMahindra.webp";
import HCL from "../assets/hcl.webp";
import TCS from "../assets/tcs.webp";
import Mindtree from "../assets/mindtree.webp";
import Wipro from "../assets/wipro.webp";
import Mphasis from "../assets/mphasis.webp";
import IndiqSoft from "../assets/indiqsoft.webp";
// import Nischita from "../assets/Nischita.webp";

function Trainers() {
  return (
    <div id="trainers">
      <h1 className="text-4xl mt-24 text-center font-bold bg-gradient-to-r from-violet-600 to-fuchsia-600 bg-clip-text text-transparent">
        Meet Our Trainers
      </h1>
      <h2 className="text-xl text-center font-bold bg-gradient-to-r from-green-500 to-green-700 bg-clip-text text-transparent">
        Learn from our Industrial experts
      </h2>

      <div className="container mt-12 mx-auto grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8 pl-10 pr-10 ">
        <div className="mx-3 mt-6 flex flex-col rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  sm:shrink-0 sm:grow sm:basis-0 border border-blue-800 ">
          <img
            src={Akhil}
            width={100}
            height={50}
            alt="picture"
            className="mx-auto mt-6 border rounded-full"
          />
          <div className="p-6">
            <h5 className="mb-2 text-xl text-center font-bold leading-tight text-purple-800 ">
              Akhil Meesala
            </h5>
            <h6 className="text-center font-semibold text-xl">Founder</h6>
            <img
              src={IqsLogo}
              width={150}
              height={150}
              alt="img"
              className="mx-auto"
            />
            <hr />
            <div className="flex justify-between space-between h-12 mt-5 overflow-hidden">
              <img src={HCL} width={70} height={70} alt="img" />
              <img src={TCS} width={70} height={70} alt="img" />
              <img src={Mindtree} width={70} height={70} alt="img" />
            </div>
          </div>
        </div>

        <div className="mx-3 mt-6 flex flex-col rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  sm:shrink-0 sm:grow sm:basis-0 border border-blue-800">
          <img
            src={Venk}
            width={100}
            height={50}
            alt="picture"
            className="mx-auto mt-6 border rounded-full"
          />
          <div className="p-6">
            <h5 className="mb-2 text-xl text-center font-bold leading-tight text-purple-800">
              Venkatesh Meesala
            </h5>
            <h6 className="text-center font-semibold text-xl">
              Managing Director
            </h6>
            <img
              src={IqsLogo}
              width={150}
              height={150}
              className="mx-auto"
              alt="img"
            />
            <hr />
            <div className="flex justify-evenly space-evenly h-12 mt-5 ">
              <img src={TCS} width={70} height={70} alt="img" />
              <img src={Mindtree} width={70} height={70} alt="img" />
            </div>
          </div>
        </div>

        <div className="mx-3 mt-6 flex flex-col rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  sm:shrink-0 sm:grow sm:basis-0 border border-blue-800">
          <img
            src={Bala}
            width={100}
            height={50}
            alt="picture"
            className="mx-auto mt-6 border rounded-full"
          />
          <div className="p-6">
            <h5 className="mb-2 mt-1 text-xl text-center font-bold leading-tight text-purple-800">
              V Balachandar
            </h5>
            <h6 className="text-center font-semibold text-xl mt-1">
              Python Developer
            </h6>
            <img
              src={TechMahindra}
              width={100}
              height={100}
              className="mx-auto mt-4"
              alt="img"
            />
            <hr />
            <div className="flex justify-evenly space-between h-12 mt-5 ">
              <img src={Wipro} width={70} height={70} alt="img" />
              <img src={Mphasis} width={70} height={70} alt="img" />
            </div>
          </div>
        </div>

        {/* <div className="mx-3 mt-6 flex flex-col rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  sm:shrink-0 sm:grow sm:basis-0 border border-blue-800 ">
          <img
            src={Nischita}
            width={100}
            height={50}
            alt="picture"
            className="mx-auto mt-6 border rounded-full"
          />
          <div className="p-6">
            <h5 className="mb-2 text-xl text-center font-bold leading-tight text-purple-800">
              Nischita Edigar
            </h5>
            <h6 className="text-center font-semibold text-xl">
              Java Developer
            </h6>
            <img
              src={IqsLogo}
              width={150}
              height={150}
              className="mx-auto"
              alt="img"
            />
            <hr />
            <div className="flex justify-evenly space-evenly h-12 mt-5 ">
              <img src={IndiqSoft} width={70} height={70} alt="img" />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Trainers;
